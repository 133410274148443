
const Header = () => {
    return (
        <header className="bg-gray-500 text-white text-center z-10 p-5 shadow-sm">
{/*             <h1 className="text-4xl m-0 font-bold underline text-green-950 bg-blue-600">
                Hello world!
            </h1> */}
            <h2>header</h2>
        </header>
    )
}

export default Header;