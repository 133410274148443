import React, { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

import "../styles/content.css";

const Content = ({ enabledCubes, cubeColors }) => {
    const { unityProvider, sendMessage, isLoaded } = useUnityContext({
        loaderUrl: "unity/Build/QS-WebGLCBT.loader.js",
        dataUrl: "unity/Build/QS-WebGLCBT.data.unityweb",
        frameworkUrl: "unity/Build/QS-WebGLCBT.framework.js.unityweb",
        codeUrl: "unity/Build/QS-WebGLCBT.wasm.unityweb",
    });

    useEffect(() => {
        if (isLoaded && enabledCubes.length > 0) {
            const cubeId = enabledCubes[0];
            const isEnabled = 1; // 활성화 상태를 Unity로 전달
            sendMessage("@Scene", "Move", isEnabled);
        }
    }, [enabledCubes, isLoaded, sendMessage]);

    useEffect(() => {
        if (isLoaded) {
            Object.entries(cubeColors).forEach(([cubeId, color]) => {
                sendMessage("@Scene", "ChangeColor", color); // 색상 전달
                console.log(`Cube ${cubeId} color sent to Unity: ${color}`);
            });
        }
    }, [cubeColors, isLoaded, sendMessage]);

    return (
        <div className="content">
            {!isLoaded && <div className="loading"></div>}
            <div
                className={`unity-container ${isLoaded ? "loaded" : ""}`}
                style={{
                    width: "900px",
                    height: "600px",
                    transition: "opacity 1s ease-in-out",
                    opacity: isLoaded ? 1 : 0,
                }}
            >
                <Unity unityProvider={unityProvider} className="unity" />
            </div>
        </div>
    );
};

export default Content;
